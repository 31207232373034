import React from 'react'

export default function Input({ type = "text", id=null, name, className = "text-small text-black input-field", value=null, onChange=null ,...other}) {

    return (
        <div className="input-field-container">
            <div className="input-field-inset">
                <input id={id} type={type} name={name} className={className} value={value} onChange={onChange} {...other} />
                <div className="input-field-suffix"></div>
            </div>
        </div>
    )
}
