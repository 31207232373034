import React, { useCallback, useState } from 'react'
import './allVideos.css'
import VideosTable from '../../components/VideosTable/VideosTable'
import AuthPageWrapper from '../../components/AuthWrapper/AuthPageWrapper'
import Input from '../../components/Input/Input'
export default function AllVideos() {

    const [search, setSearch] = useState({start:'',end:'',search:''});

    const handleChangeSearch = (event) => {
        setSearch(prev=>({...prev,[event.target.name]:event.target.value}));
    }


    const handleClear = () => {
        setSearch({start:'',end:'',search:''});
    
    }


    return (
        <AuthPageWrapper roles={['Admin', 'ContentManager']}>

            <div className='video-page-wrepper'>
                <div className='page-header'>
                    כל הסרטונים
                    <hr />
                </div>
                <div className='search-wrapper'>
                    <label>חיפוש
                        <Input type='search' name='search' value={search.search} onChange={handleChangeSearch} placeholder='חיפוש' aria-label='search' />
                    </label>
                    <label>מ-
                        <Input type='date' name='start' value={search.start} onChange={handleChangeSearch} aria-label='start-date'/>
                    </label>
                    <label>עד-
                        <Input type='date' name='end' value={search.end} onChange={handleChangeSearch} aria-label='end-date'/>
                    </label>
                    <button className="button" onClick={handleClear}>נקה</button>
                </div>

                <div className='page-container'>
                    <VideosTable search={search} />

                </div>
            </div>
        </AuthPageWrapper>
    )
}
