import React, { useContext, useEffect, useState } from 'react'
import { appContext } from '../../service/AppContext';
import VideoTableRow from './VideoTableRow';
import './videosTable.css'

export default function VideosTable({search}) {
    const [videos, setVideos] = useState([])
    const [pagination, setPageination] = useState({ size: 10, page: 1, pages: 1 })
    const pageStart = (pagination.page - 1) * pagination.size
    const { breslevRequest } = useContext(appContext);




    useEffect(() => {
        async function fetchAllVideos() {
            let response = await breslevRequest({ route: '/video/', params: { size: pagination.size, page: pagination.page, search } })
            if (response?.status === 200) {
                setVideos(response.data?.videos)
                setPageination(prev => { return { ...prev, amount: response.data?.amount, pages: response.data?.pages, page: response.data?.page } })
            }
        }
        fetchAllVideos();
    }, [search, breslevRequest, pagination.size, pagination.page])

    const handleJumpToPage = async (page) => {
        let response = await breslevRequest({ route: '/video/', params: { ...pagination, page ,search} })
        if (response?.status === 200) {
            setVideos(response.data?.videos)
            setPageination(prev => { return { ...prev, amount: response.data?.amount, pages: response.data?.pages, page: response.data?.page } })
        }
    }

    const handleRemoveCB = (id) => {
        return function () {
            setVideos(videos => videos.filter(video => video.id !== id))
        }
    }

    const handleUpdateCB = (id) => {
        return function (update) {
            if (typeof (update) === 'object')
                setVideos(videos => videos.map(video => (video.id === id) ? Object.assign(video, update) : video))
        }
    }


    return (
        <table className='videos-table'>

            <thead>
                <tr>
                    <th>מספר</th>
                    <th>שם</th>
                    <th>כותרת</th>
                    <th>תיאור</th>
                    <th>תאריך העלאה</th>
                    <th>קישור</th>
                    <th>סטטוס</th>
                    <th>פוסט</th>
                    <th>אפשרויות</th>
                </tr>
            </thead>
            <tbody>
                {videos?.map((video, idx) => <VideoTableRow key={video.id} number={pageStart + 1 + idx} handleRemove={handleRemoveCB(video.id)} handleUpdate={handleUpdateCB(video.id)} {...video} />)}
            </tbody>
            {(pagination.pages > 1) &&
                < tfoot >
                    <tr  >
                        <td colSpan='100%'>
                            <div className='pagination'>
                                <button className='pageination-button' onClick={() => { handleJumpToPage(pagination?.page - 1) }} disabled={pagination?.page === 1}>
                                    הקודם
                                </button>
                                <div className='pagination-numbers'>
                                    {(pagination.pages < 12) ?
                                        <>
                                            {[...Array(pagination.pages)].map((v, idx) => {
                                                let isCurrent = (pagination?.page === (idx + 1));
                                                return < button className={(isCurrent) ? 'pageination-page current-page' : 'pageination-page'} onClick={() => handleJumpToPage(idx + 1)} key={idx} disabled={isCurrent} >{idx + 1}</button>
                                            })}
                                        </>
                                        :
                                        <>
                                            {(pagination.page > 5 && pagination.page < pagination.pages - 4) ? <>
                                                {[...Array(3)].map((v, idx) => {
                                                    let isCurrent = (pagination?.page === (idx + 1));
                                                    return < button className={(isCurrent) ? 'pageination-page current-page' : 'pageination-page'} onClick={() => handleJumpToPage(idx + 1)} key={idx} disabled={isCurrent} >{idx + 1}</button>
                                                })}
                                                {pagination.page > 6 && <>...</>}
                                                {[...Array(5)].map((v, idx) => {
                                                    let pageNumber = pagination.page - 2 + idx;
                                                    if (pageNumber < 1 || pageNumber > pagination.pages) return <></>
                                                    let isCurrent = (pagination?.page === pageNumber);
                                                    return < button className={(isCurrent) ? 'pageination-page current-page' : 'pageination-page'} onClick={() => handleJumpToPage(pageNumber)} key={pageNumber} disabled={isCurrent} >{pageNumber}</button>
                                                })}
                                                {pagination.page < pagination.pages - 5 && <>...</>}

                                                {[...Array(3)].map((v, idx) => {
                                                    let pageNumber = pagination.pages - 2 + idx;
                                                    if (pageNumber < 1) return <></>
                                                    let isCurrent = (pagination?.page === pageNumber);
                                                    return < button className={(isCurrent) ? 'pageination-page current-page' : 'pageination-page'} onClick={() => handleJumpToPage(pageNumber)} key={pageNumber} disabled={isCurrent} >{pageNumber}</button>
                                                })}
                                            </>
                                                :
                                                <>
                                                    {[...Array(6)].map((v, idx) => {
                                                        let isCurrent = (pagination?.page === (idx + 1));
                                                        return < button className={(isCurrent) ? 'pageination-page current-page' : 'pageination-page'} onClick={() => handleJumpToPage(idx + 1)} key={idx} disabled={isCurrent} >{idx + 1}</button>
                                                    })}

                                                    ....
                                                    {[...Array(6)].map((v, idx) => {
                                                        let pageNumber = pagination.pages - 5 + idx;
                                                        if (pageNumber < 1) return <></>
                                                        let isCurrent = (pagination?.page === pageNumber);
                                                        return < button className={(isCurrent) ? 'pageination-page current-page' : 'pageination-page'} onClick={() => handleJumpToPage(pageNumber)} key={pageNumber} disabled={isCurrent} >{pageNumber}</button>
                                                    })}
                                                </>
                                            }
                                        </>
                                    }

                                </div>
                                <button className='pageination-button' onClick={() => { handleJumpToPage(pagination?.page + 1) }} disabled={pagination?.page === pagination?.pages}>
                                    הבא
                                </button>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan='100%'>
                            <div>
                                עמוד&nbsp;{pagination.page}&nbsp;מתוך&nbsp;{pagination.pages},&nbsp;סה"כ&nbsp;:&nbsp;{pagination.amount}&nbsp;סרטונים
                            </div>
                        </td>
                    </tr>
                </tfoot>
            }
        </table >


    )
}
